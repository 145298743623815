// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_CIfe1 .zp_O3YaB{max-width:20px;max-height:20px}.zp_CIfe1 .zp_i6CnH{max-width:200px;max-height:200px}.zp_CIfe1 .zp_CWjb9{max-width:40px;max-height:40px}.zp_CWh1C{display:block}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "zp_CIfe1",
	"small": "zp_O3YaB",
	"large": "zp_i6CnH",
	"medium": "zp_CWjb9",
	"fallbackWrapper": "zp_CWh1C"
};
export default ___CSS_LOADER_EXPORT___;

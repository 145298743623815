/* eslint-disable leadgenie/prevent-context-provider-for-design-system-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps, forwardRef, useContext } from 'react';
import { Button } from '@ariakit/react';
import { RouterProviderContext } from '../../router-provider/RouterProvider';

import type { To } from '$types/lib/react-router-dom';

// Styles
import styles from './UnstyledLink.module.scss';

import classNames from 'classnames';

type UnstyledLinkProps = {
  /** Optional class name for styling the link. */
  className?: string;

  /** Destination URL when using a router.
   * @type string | Partial<Path>
   */
  to?: To;

  /** Destination URL for standard hyperlinks. */
  href?: string;

  /** Specifies the browsing context in which the URL will be opened. */
  target?: '_blank' | '_self';

  /** Children elements or text within the link. */
  children?: React.ReactNode;

  /**
   * Whether button should stay accessible when disabled. It allows adding tooltips and improves discoverability.
   *
   * @default `true`
   */
  accessibleWhenDisabled?: boolean;

  /** Whether the link is disabled. */
  disabled?: boolean;

  /**
   * Defines a string value that labels the current element.
   */
  'aria-label'?: string;

  /**
   * Identifies the element (or elements) that labels the current element.
   */
  'aria-labelledby'?: string;

  /**
   * Identifies the element (or elements) that describes the object.
   */
  'aria-describedby'?: string;

  /**
   * Identifies the element (or elements) that provide a detailed, extended description for the object.
   */
  'aria-details'?: string;
} & Omit<ComponentProps<typeof Button<'a'>>, 'as'>;

export const UnstyledLink = forwardRef<HTMLAnchorElement, UnstyledLinkProps>(function UnstyledLink(
  props,
  ref,
) {
  const { className, to, href, target, children, accessibleWhenDisabled, ...domProps } =
    adaptDisabledProp(props, props.accessibleWhenDisabled ?? true);
  const { Link: RouterLink } = useContext(RouterProviderContext);

  const linkElement = href ? (
    <a
      href={href}
      target={target ?? '_blank'}
      rel="noreferrer noopener"
      {...domProps}
      data-href={href}
      className={classNames(styles.link, className)}
    >
      {children}
    </a>
  ) : (
    <RouterLink
      to={to}
      data-to={to}
      target={target}
      {...domProps}
      className={classNames(styles.link, className)}
    >
      {children}
    </RouterLink>
  );

  // @ts-expect-error Issue with render prop, not sure how to fix.
  return <Button ref={ref} render={linkElement} />;
});

function adaptDisabledProp(
  props: ComponentProps<typeof UnstyledLink>,
  accessibleWhenDisabled: boolean = true,
) {
  if (props.disabled) {
    if (accessibleWhenDisabled) {
      return {
        ...props,
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => e.preventDefault(),
        href: undefined,
        to: undefined,
        disabled: undefined,
        'aria-disabled': props.disabled,
      };
    }

    const { disabled, ...rest } = props;
    return {
      ...rest,
      'aria-disabled': props.disabled,
    };
  }

  return props;
}

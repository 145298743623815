import $ from 'jquery';
import { trim } from 'lodash-es';
import DOMPurify from 'dompurify';

/**
 * @param {string} htmlString
 *
 */
export function minifyHtml(htmlString: string): string {
  return htmlString.replace(/>[\n\t ]+</g, '><');
}

/**
 * @param {string} htmlString
 * @param {Record<string, unknown>} [options]
 * @param {Record<string, unknown>} [sanitizeCfg]
 *
 */
export function sanitizeHtmlString(
  htmlString?: string,
  options: Record<string, unknown> = {},
  sanitizeCfg: Record<string, unknown> = { ADD_ATTR: ['target'] },
): string {
  const { sanitizeLinks = true, replaceNbsp = false } = options;

  const $html = $(`<div>${DOMPurify.sanitize(htmlString, sanitizeCfg) ?? ''}</div>`);
  $html.find('style').remove();

  if (sanitizeLinks) {
    $html
      .find(
        'a:not([href^="http://"]):not([href^="https://"]):not([href^="mailto:"]):not([href^="tel:"]):not([href^="ftp://"])',
      )
      .each((_, element) => {
        if ($(element).attr('href')) {
          $(element).attr('href', `https://${$(element).attr('href')}`);
        }
      });
  }

  if (replaceNbsp) {
    $html.find('*').each((_, element) => {
      if (!element.children.length) {
        element.innerHTML = replaceNbsps(element.innerHTML);
      }
    });
  }

  return minifyHtml($html.html());
}

/**
 * This function will convert the html string to text. This should work
 * well for parsing text from the rich text editor.
 */
export function htmlToString(htmlString: string, removeDuplicatedLineBreaks = true): string {
  const sanitizedString = trim(
    sanitizeHtmlString(htmlString)
      // Replace all <br> tags with a new line character.
      .replace(/<br\s*\/?>/gi, '\n')
      // Remove all word modifiers tags.
      .replace(/<\/?(b|strong|u|i|s|em|mark|small|del|ins|sub|sup)[^>]*>/gi, '')
      // Replace all opening tags with a new line character.
      .replace(/<[^/][^>]*>/gi, '\n')
      // Remove all remaining HTML tags.
      .replace(/<[^>]+>/g, ''),
  );

  if (removeDuplicatedLineBreaks) {
    return sanitizedString.replace(/\n+/g, '\n');
  }

  return sanitizedString;
}

export function replaceNbsps(str: string) {
  return str
    .replaceAll('&nbsp;', ' ') // replace nbsp
    .replace(/\s{2,}/g, ' '); // replace multiple spaces
}

export function sanitizeHTML(html: string | Node) {
  return DOMPurify.sanitize(html || '', { USE_PROFILES: { html: true } });
}

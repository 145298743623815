import type { ComponentProps } from 'react';
import classNames from 'classnames';
import { TooltipRenderer } from 'common/components/design-system/tooltip/TooltipRenderer';
import type { BadgeVariantType } from './types';

// styles
import styles from './BadgeDot.module.scss';

type BadgeDotProps = {
  /**
   * Label for the badge.
   */
  label?: string;

  /**
   * Visual variant
   *
   * @default primary
   */
  variant?: BadgeVariantType;

  /**
   * Additional className
   */
  className?: string;

  /**
   * Change the size of this component
   */
  size?: 'xsmall';

  /**
   * Tooltip content to display when hovering over the badge.
   *
   * @default undefined
   */
  tooltip?: ComponentProps<typeof TooltipRenderer>['tooltip'];
};

export const BadgeDot = function BadgeDot({
  variant = 'primary',
  tooltip,
  size,
  label = '',
  className,
}: BadgeDotProps) {
  return (
    <TooltipRenderer tooltip={tooltip}>
      <div
        className={classNames(
          styles.badgeDot,
          styles[`${variant}Dot`],
          styles[`${size}Size`],
          className,
        )}
        aria-label={label}
      />
    </TooltipRenderer>
  );
};

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_D4zww{display:flex;flex-direction:column;position:fixed;top:50%;left:50%;transform:translate(-50%, -50%);width:80%;max-width:660px;max-height:72%;border-radius:var(--border-radius-8);background:var(--color-ui-background-primary);box-shadow:0 12px 16px -4px rgba(26,26,26,.08),0px 4px 6px -2px rgba(26,26,26,.03)}.zp_D4zww.zp_uA9ii{max-width:480px}.zp_D4zww.zp_Yzzz7{max-width:880px;max-height:80%}.zp_TgAB_{box-shadow:var(--elevation-3)}.zp_pmJ0n{font-size:14px;font-weight:400;line-height:20px;flex:1;overflow-y:auto;padding:16px 24px;color:var(--color-ui-text-base-secondary)}.zp_XATXq{opacity:.2;background:var(--color-base-sand-80)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "zp_D4zww",
	"small": "zp_uA9ii",
	"large": "zp_Yzzz7",
	"shadow": "zp_TgAB_",
	"content": "zp_pmJ0n",
	"backdrop": "zp_XATXq"
};
export default ___CSS_LOADER_EXPORT___;

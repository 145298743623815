import React, { useState, useEffect, createContext, useMemo } from 'react';
import classNames from 'classnames';

import styles from './ProgressSteps.module.scss';
import { FocusScope, useFocusManager } from 'react-aria';

const ProgressStepContext = createContext<
  | {
      linear?: ProgressStepsProps['linear'];
      direction?: ProgressStepsProps['direction'];
    }
  | undefined
>(undefined);

function useProgressStepContext() {
  const context = React.useContext(ProgressStepContext);
  if (context === undefined) {
    throw new Error('useProgressStepContext must be used within a ProgressStepsProvider');
  }
  return context;
}

type StepProps = {
  /**
   * Step label
   */
  label: string;
  /**
   * Step description
   */
  description?: string;
  /**
   * State of current step
   */
  state?: 'current' | 'incomplete' | 'error' | 'complete';
  /**
   * Internal prop
   */
  onClick?(): void;
};

const Step: React.FC<StepProps> = (props) => {
  const { label, description, state, onClick } = props;
  const { linear, direction } = useProgressStepContext();

  const focusManager = useFocusManager();
  const onKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (e) => {
    const navKeys =
      direction === 'horizontal'
        ? { next: 'ArrowRight', prev: 'ArrowLeft' }
        : { next: 'ArrowDown', prev: 'ArrowUp' };

    switch (e.key) {
      case navKeys.next:
        focusManager.focusNext({ wrap: true });
        break;
      case navKeys.prev:
        focusManager.focusPrevious({ wrap: true });
        break;
      default:
    }
  };

  const core = (
    <>
      <span className={styles.label}>{label}</span>
      {description ? <span className={styles.description}>{description}</span> : null}
    </>
  );

  return (
    <li
      className={classNames(styles.step, {
        [styles.current]: state === 'current',
        [styles.incomplete]: state === 'incomplete',
        [styles.error]: state === 'error',
        [styles.complete]: state === 'complete',
      })}
      key={label}
      aria-current={state === 'current' ? 'step' : undefined}
    >
      {linear ? (
        <div className={styles.labelWrapper}>{core}</div>
      ) : (
        <button
          type="button"
          className={styles.labelWrapper}
          onKeyDown={onKeyDown}
          onClick={onClick}
        >
          {core}
        </button>
      )}
    </li>
  );
};

type ProgressStepsProps = {
  /**
   * Direction of checkbox group
   */
  direction?: 'vertical' | 'horizontal';
  /**
   * Linear - limited to move forward/backwards, depends on validation state of step
   *
   * Non-linear - any step is available for activation
   */
  linear?: boolean;
  /**
   * Steps to display
   */
  steps?: StepProps[];
  /**
   * Set the current step index
   */
  setStepIndex?: React.Dispatch<React.SetStateAction<number>>;
};
/**
 * Progress steps display the user’s location within a multistep process while providing the status of each step.
 *
 * Progress steps are a visual representation of a user’s progress through a set of steps, guiding toward the completion of a specified process.
 * Use progress steps to keep the user on track when completing a specific task.
 * By dividing the end goal into smaller, sub-tasks, it increases the percentage of completeness as each task is completed.
 *
 * Stepper can be **linear**, which defines transition logic between steps:
 * - Only moving forward or backward is allowed. Steps cannot be skipped.
 * - Invalid step cannot be skipped.
 *
 * Stepper can be **non-linear**, which allows to activate any step anytime
 */
export function ProgressSteps(props: ProgressStepsProps) {
  const { steps, direction, linear, setStepIndex } = props;

  const containerClassname = classNames([
    styles.container,
    direction === 'vertical' && styles.vertical,
    linear && styles.linear,
  ]);

  const value = useMemo(
    () => ({
      linear,
      direction,
    }),
    [linear, direction],
  );

  const core = steps?.map((step, index) => (
    <Step {...step} onClick={() => setStepIndex?.(index)} key={step.label} />
  ));

  return (
    <ProgressStepContext.Provider value={value}>
      <ol className={containerClassname} aria-label="progress">
        {linear ? core : <FocusScope>{core}</FocusScope>}
      </ol>
    </ProgressStepContext.Provider>
  );
}

ProgressSteps.Step = Step;

/**
 * Defines stepper behaviour
 * Linear - limited to move forward/backwards, depends on validation state of step
 */
export const useProgressStepsLinear = (initialSteps: StepProps[]) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState<StepProps[]>(() =>
    initialSteps.map((_step, index) => ({
      state: index === 0 ? 'current' : 'incomplete',
      ..._step,
    })),
  );

  const changeStepState = (newState: StepProps['state'], stepToChangeIndex = stepIndex) => {
    if (stepToChangeIndex >= steps.length || stepToChangeIndex < 0) {
      return;
    }
    setSteps((_steps) =>
      _steps.map((step, index) => {
        if (index === stepToChangeIndex) {
          return {
            ...step,
            state: newState,
          };
        }

        return step;
      }),
    );
  };

  const isProcessCompleted = () => stepIndex === steps.length;

  const completeStep = () => {
    if (isProcessCompleted() || steps[stepIndex].state === 'error') {
      return;
    }
    setStepIndex((i) => Math.min(i + 1, steps.length));
  };

  const goBack = () => {
    setStepIndex((i) => Math.max(i - 1, 0));
  };

  useEffect(() => {
    if (isProcessCompleted()) {
      changeStepState('complete', stepIndex - 1);
    } else {
      changeStepState('incomplete', stepIndex + 1);
      changeStepState('complete', stepIndex - 1);
      changeStepState('current', stepIndex);
    }
  }, [stepIndex]);

  return {
    completeStep,
    goBack,
    changeStepState,
    renderProps: { steps, setStepIndex, setSteps, linear: true },
  };
};

/**
 * Defines stepper behaviour
 * Non-linear - any step is available for activation
 */
export const useProgressStepsNonLinear = (initialSteps: StepProps[]) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState<StepProps[]>(() =>
    initialSteps.map((_step) => ({
      state: 'incomplete',
      ..._step,
    })),
  );

  const changeStepState = (newState: StepProps['state'], stepToChangeIndex = stepIndex) => {
    if (stepToChangeIndex >= steps.length || stepToChangeIndex < 0) {
      return;
    }
    setSteps((_steps) =>
      _steps.map((step, index) => {
        if (index === stepToChangeIndex) {
          return {
            ...step,
            state: newState,
          };
        }

        return step;
      }),
    );
  };

  return {
    changeStepState,
    renderProps: {
      steps: steps.map((step, index) =>
        index === stepIndex ? { ...step, state: 'current' as const } : step,
      ),
      setStepIndex,
      setSteps,
      linear: false,
    },
  };
};

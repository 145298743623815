/* eslint-disable react/jsx-props-no-spreading */
import React, { type ComponentProps, type ComponentPropsWithRef, forwardRef } from 'react';
import classNames from 'classnames';

// Components
import type { Button } from './Button';

// Styles
import styles from './ButtonGroup.module.scss';

type ButtonGroupProps = ComponentProps<'div'> & {
  size?: ComponentPropsWithRef<typeof Button>['size'];
};
/**
 * The `ButtonGroup` component is used to group multiple `Button` components together.
 */
export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(function ButtonGroup(
  props,
  ref,
): JSX.Element {
  const { children, size = 'medium', className, ...restProps } = props;
  return (
    <div ref={ref} className={classNames(styles.buttonGroup, className)} {...restProps}>
      {React.Children.map(children, function mapChildren(child) {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { ...child.props, size });
        }

        return child;
      })}
    </div>
  );
});

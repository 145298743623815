/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable leadgenie/prevent-context-provider-for-design-system-components */
import React, {
  type ComponentProps,
  type PropsWithChildren,
  createContext,
  forwardRef,
  useContext,
  useMemo,
} from 'react';

import type { To } from '$types/lib/react-router-dom';

type LinkProps = PropsWithChildren<
  ComponentProps<'a'> & {
    to?: To;
  }
>;

const DefaultLinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(function DefaultLinkComponent(
  props,
  ref,
) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const { navigate } = useContext(RouterProviderContext);
  const { to, onClick, children, ...restProps } = props;
  return (
    <a
      {...restProps}
      ref={ref}
      href={to as string}
      target={to ? '_self' : '_blank'}
      rel="noreferrer"
      onClick={(e) => {
        if (typeof onClick === 'function') {
          onClick(e);
        }
        if (!e.defaultPrevented) {
          navigate(to);
        }
      }}
    >
      {children}
    </a>
  );
});

const DefaultValue = {
  navigate() {
    console.warn(
      `RouterProvider is not configured. Navigation will not be performed. Arguments sent: ${JSON.stringify(
        arguments,
      )}`,
    );
  },
  Link: DefaultLinkComponent,
} as const;

type RouterContextType = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Link: any;
};

type RouterProviderProps = PropsWithChildren<RouterContextType>;

export const RouterProviderContext = createContext<RouterContextType>(DefaultValue);

/**
 *
 * The RouterProvider component accepts two props: navigate and Link. `navigate` should be set to a function received from your router for performing a client side navigation programmatically. `Link` should be the link component provided by your router. This system allows the consumers to use any routing library.
 *
 * Component inspired from react-aria: https://react-spectrum.adobe.com/react-aria/routing.html
 *
 * @example
 * ```
 * function App() {
 *   const navigate = useNavigate();
 *
 *   return <RouterProvider navigate={navigate}>...</RouterProvider>
 * }
 * ```
 *
 */
export function RouterProvider({ navigate, Link, children }: RouterProviderProps) {
  const value = useMemo(() => ({ navigate, Link }), [navigate, Link]);
  return <RouterProviderContext.Provider value={value}>{children}</RouterProviderContext.Provider>;
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_YIuLn{border-radius:var(--border-radius-8);background-color:var(--color-base-sand-80);color:var(--color-ui-text-base-primary-inverse)}.zp_YIuLn:not(.zp_vcMj1){text-align:center}.zp_YIuLn.zp_vcMj1{text-align:left;word-break:break-word}.zp_YIuLn[data-theme=\"tooltip-2.0\"] .tippy-content{max-width:260px;padding:8px 12px;font-size:12px;font-weight:400;line-height:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "zp_YIuLn",
	"multiline": "zp_vcMj1"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_mldnC{max-width:100%;padding:0}.zp_mldnC[data-focus-visible=true]{border-radius:var(--border-radius-8);outline:1px solid var(--color-base-ocean-50);outline-offset:1px}.zp_lQfvG.zp_IwhTp{display:flex;align-items:center;justify-content:center;gap:4px;background-color:var(--color-base-pebble-20);max-width:178px;padding:2px 4px 2px 2px;border-radius:var(--border-radius-8)}.zp_lQfvG.zp_IwhTp .zp_tRbzQ{color:var(--color-base-pebble-70)}.zp_lQfvG.zp_IwhTp .zp_jSdav{font-size:12px;font-weight:400;line-height:16px;color:var(--color-base-pebble-70);text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "zp_mldnC",
	"wrapper": "zp_lQfvG",
	"credits": "zp_IwhTp",
	"creditsIcon": "zp_tRbzQ",
	"label": "zp_jSdav"
};
export default ___CSS_LOADER_EXPORT___;

import classnames from 'classnames';
import { usePrefersReducedMotion } from 'common/hooks/usePrefersReducedMotion';
import { LottiePlayer } from '../lottie-player/LottiePlayer';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './LoadingAnimation.module.scss';
import { useEffect, useMemo, useRef } from 'react';
import largeAnimationFrame from 'common/components/design-system/lotties/loading-animations/large-static.svg';
import mediumAnimationFrame from 'common/components/design-system/lotties/loading-animations/medium-static.svg';
import smallAnimationFrame from 'common/components/design-system/lotties/loading-animations/small-static.svg';

const LOTTIE_IMPORTERS = {
  small: () => import('common/components/design-system/lotties/loading-animations/small.json'),
  medium: () => import('common/components/design-system/lotties/loading-animations/medium.json'),
  large: () => import('common/components/design-system/lotties/loading-animations/large.json'),
};

const STATIC_LOTTIE_FALLBACKS = {
  small: smallAnimationFrame,
  medium: mediumAnimationFrame,
  large: largeAnimationFrame,
};

type LoadingAnimationProps = {
  /**
   * The size of the loading animation.
   * @default medium
   */
  variant?: 'small' | 'medium' | 'large';
};

/**
 * The Loading Animation component leverages Lottie animations to display engaging loading indicators. Available in small, medium, and large variants, it offers flexibility to fit different UI spaces and contexts.
 */
export const LoadingAnimation = ({ variant = 'medium' }: LoadingAnimationProps) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && !ref.current.closest("[aria-busy='true']")) {
      throw new Error('LoadingAnimation must be wrapped in a component with aria-busy=true');
    }
  }, []);

  const fallbackTemplate = useMemo(() => {
    return (
      <img
        className={classnames(styles[variant], styles.fallbackWrapper)}
        src={STATIC_LOTTIE_FALLBACKS[variant]}
        alt="Loading"
      />
    );
  }, [variant]);

  return (
    <div
      role="img"
      className={styles.wrapper}
      data-testid="loadingAnimation"
      ref={ref}
      aria-hidden={true}
    >
      <LottiePlayer
        fallback={fallbackTemplate}
        importer={LOTTIE_IMPORTERS[variant]}
        className={styles[variant]}
        autoplay={!prefersReducedMotion}
        loop={!prefersReducedMotion}
      />
    </div>
  );
};

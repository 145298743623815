// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_BaHWZ{display:flex;justify-content:space-between;align-items:center;gap:16px;padding:16px 24px;min-width:max-content;border-top:1px solid var(--color-base-sand-20)}.zp_GEuG9{display:none;font-size:14px;font-weight:400;line-height:20px;color:var(--color-ui-text-base-secondary);margin:0}@media(min-width: 768px){.zp_GEuG9{display:inline;display:initial}}.zp_jWSth,.zp_g_9FU{display:flex;justify-content:flex-end;gap:24px;align-items:center;flex:1 0 max-content}.zp_jWSth{justify-content:flex-start}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "zp_BaHWZ",
	"text": "zp_GEuG9",
	"left": "zp_jWSth",
	"right": "zp_g_9FU"
};
export default ___CSS_LOADER_EXPORT___;

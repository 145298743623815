import type { RefObject } from 'react';
import React, { Component } from 'react';
import classnames from 'classnames';

import { isApolloEverywhere, isLinkedin } from 'chrome-extension/inject/core/lib/locationUtils';

import styles from './ResetWrapper.module.scss';

export const apolloWrapperClasses = ['zp', 'apolloio-css-vars-reset', styles['reset-wrapper']];

type ResetWrapperProps = {
  parentRef?: RefObject<HTMLDivElement> | null;
  className?: string;
};

export default class ResetWrapper extends Component<ResetWrapperProps> {
  static defaultProps = {
    parentRef: null,
    className: null,
  };

  render() {
    return (
      <div
        ref={this.props.parentRef}
        className={classnames(
          'apolloio-css-vars-reset',
          styles['reset-wrapper'],
          this.props.className,
          isApolloEverywhere() && styles.apolloEverywhereRevert,
          isLinkedin() && styles.linkedinRevert,
        )}
        dir="ltr"
      >
        {this.props.children}
      </div>
    );
  }
}

// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zp_gWdVo{width:8px;height:8px;border-radius:50%;background-color:var(--color-base-ocean-60);flex:0 0 auto}.zp_gWdVo.zp_SXfBZ{background:var(--color-base-evergreen-50)}.zp_gWdVo.zp_Y3ixD{background:var(--color-base-ember-50)}.zp_gWdVo.zp_dLZe2{background:var(--color-base-rust-50)}.zp_gWdVo.zp_R6zXi{background:var(--color-base-tropic-50)}.zp_gWdVo.zp_KbTxZ{background:var(--color-base-pebble-60)}.zp_gWdVo.zp_ktY4l{background:var(--color-base-iris-50)}.zp_gWdVo.zp_LwzS9{background:var(--color-base-moss-60)}.zp_gWdVo.zp_AcbFw{background:var(--color-base-sand-50)}.zp_gWdVo.zp_Tdk_r{background:var(--color-base-sand-80)}.zp_gWdVo.zp_nBEqe{background:var(--color-base-sakura-50)}.zp_gWdVo.zp_f4s1S{width:4px;height:4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeDot": "zp_gWdVo",
	"positiveDot": "zp_SXfBZ",
	"cautionDot": "zp_Y3ixD",
	"negativeDot": "zp_dLZe2",
	"infoDot": "zp_R6zXi",
	"secondaryDot": "zp_KbTxZ",
	"quaternaryDot": "zp_ktY4l",
	"quinaryDot": "zp_LwzS9",
	"neutralDot": "zp_AcbFw",
	"darkDot": "zp_Tdk_r",
	"tertiaryDot": "zp_nBEqe",
	"xsmallSize": "zp_f4s1S"
};
export default ___CSS_LOADER_EXPORT___;
